import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentList from '../components/content-list/content-list';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';
import WYSIWYG from '../components/wysiwyg/wysiwyg';
import { get } from '../helpers/hooks-helpers';

const EventsPage = ({ data }) => {
  // 2024 Update -- Venues will now include Places and Dining Content Types
  // create variable to add the Place edges and Dining edges together
  const consolidatedVenues = [...data.allNodePlace.edges, ...data.allNodeDining.edges]

  const filters = [
    {
      title: 'Event Type',
      field: 'field_tags',
      data: data.allTaxonomyTermEventTags.edges.filter(
        ({ node }) => node.hasOwnProperty('field_hide_event_type_filter') ? !node.field_hide_event_type_filter : true
      )
    },
    {
      title: 'Series',
      field: 'field_series',
      data: data.allTaxonomyTermSeries.edges.filter(
        ({ node }) => node.hasOwnProperty('field_hide_event_type_filter') ? !node.field_hide_event_type_filter : true
      )
    },
    {
      title: 'Venue',
      field: 'field_venue',
      data: consolidatedVenues
    }
  ];

  return (
    <DefaultLayout data={data}>
      <SEO
        title="Events at City Springs"
        pageUrl="/events"
        siteConfig={data.site.siteMetadata}
      />
      <ContentList
        data={get(data, 'allNodeEvent.edges', [])}
        filters={filters}
        style={{ marginBottom: '95px' }}
      >
        <WYSIWYG>
          <h1 className="heading-2">Events at City Springs</h1>
        </WYSIWYG>
      </ContentList>
    </DefaultLayout>
  );
};

EventsPage.propTypes = {
  data: PropTypes.object
};

export default EventsPage;

export const query = graphql`
  query EventsPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allNodeEvent(
      sort: { fields: [field_date___value], order: ASC },
      filter: { field_private_event: { ne: true } }
    ) {
      edges {
        node {
          ...UpcomingEvents
        }
      }
    }
    allTaxonomyTermEventTags(sort: { fields: [weight], order: ASC }) {
      edges {
        node {
          name
          drupal_id
          field_hide_event_type_filter
        }
      }
    }
    allTaxonomyTermSeries(sort: { fields: [weight], order: ASC }) {
      edges {
        node {
          name
          drupal_id
          field_hide_event_type_filter
        }
      }
    }
    allNodePlace {
      edges {
        node {
          ... on VenueUnion {
            ... on node__dining {
              title
              drupal_id
            }
            ... on node__place {
              title
              drupal_id
              path {
                alias
              }

            }

          }
        }
      }
    }
    allNodeDining {
      edges {
        node {
          ... on VenueUnion {
            ... on node__dining {
              title
              drupal_id
            }
            ... on node__place {
              title
              drupal_id
              path {
                alias
              }
            }
          }
        }
      }
    }
  }
`;
